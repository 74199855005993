<template>
  <!-- 素材列表页 -->
  <div class="pdf">
    <DownLoad></DownLoad>
    <!-- 左侧模块 -->
    <div class="lock" @click="lockSort">
      <img class="lockImg" src="http://moolsvideo.oss-cn-beijing.aliyuncs.com/baiduOCR/2022-11-02-14-11-21_3473669.png"
        alt v-show="sortAbled" />
      <img class="lockImg" src="http://moolsvideo.oss-cn-beijing.aliyuncs.com/baiduOCR/2022-11-02-14-12-25_9033983.png"
        alt v-show="!sortAbled" />
      <!-- <i class="el-icon-lock" v-show="!sortAbled"></i> 
      <i class="el-icon-unlock"  v-show ="sortAbled"></i>-->
    </div>
    <div class="left-module">
      <div class="addnewProject">
        <!-- 搜索框 -->
        <div class="div-height">
          <div class="search-div30">
            <el-button @click="leftHandleOpen('insert', '添加班级')" class="left themeButton">添加班级</el-button>
          </div>
          <div class="search-div70">
            <el-select v-model="class_id" placeholder="请选择" class="input-div search-el-select" @clear="classClearOption()"
              @change="classChangeOption()">
              <!-- 查没有绑定课程的项目 -->
              <el-option label="全部课程" :value="0"></el-option>
              <el-option v-for="item in classList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </div>
        </div>
        <!-- 班级列表 -->
        <div>
          <div class="projectListBox">
            <!-- 拖拽排序时显示 -->
            <table class="sortableTable" v-show="sortAbled">
              <tr :id="item.id" v-for="(item, index) in groupList" :key="index">
                <!-- 班级名称 -->
                <td class="td-project" :style="item.type == 1 ? 'color:red' : ''"
                  @click="getcurrentActive(item.id, item.courseid)">
                  <i class="el-icon-success green" v-if="item.isopen"></i>
                  {{ item.name }}
                </td>
                <!-- 操作 -->
                <td class="td-handle">
                  <el-dropdown>
                    <span class="el-dropdown-link">
                      <i class="el-icon-more"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item
                        @click.native="leftHandleOpen('rename', '班级重命名', item.id, item.name, item.courseid, item.type)">重命名</el-dropdown-item>
                      <el-dropdown-item
                        @click.native="leftHandleOpen('delete', '删除班级', item.id, item.name, item.courseid)">删除</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </td>
              </tr>
            </table>
            <!-- 非拖拽排序时显示 -->
            <table v-show="!sortAbled">
              <tr :id="item.id" v-for="(item, index) in groupList2" :key="index">
                <!-- 班级名称 -->
                <td class="td-project" :style="item.type == 1 ? 'color:red' : ''"
                  @click="getcurrentActive(item.id, item.courseid)">
                  <i class="el-icon-success green" v-if="item.isopen"></i>
                  {{ item.name }}
                </td>
                <!-- 操作 -->
                <td class="td-handle">
                  <el-dropdown>
                    <span class="el-dropdown-link">
                      <i class="el-icon-more"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item
                        @click.native="leftHandleOpen('rename', '班级重命名', item.id, item.name, item.courseid, item.type)">重命名</el-dropdown-item>
                      <el-dropdown-item
                        @click.native="leftHandleOpen('delete', '删除班级', item.id, item.name, item.courseid)">删除</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>

    <!-- 左侧班级列表——弹窗 -->
    <el-dialog :title="leftDialogTitle" :visible.sync="leftDialogVisible" width="30%">
      <!-- 写入 -->
      <span v-if="leftDialogSign == 'insert' || leftDialogSign == 'rename'">
        <span>
          <span>班级名称：</span>
          <span>
            <el-input v-model="groupName" placeholder="请输入班级名称" class="width222"></el-input>
          </span>
        </span>
        <br v-if="leftDialogSign == 'insert'" />
        <br />
        <span v-if="leftDialogSign == 'insert'">
          <span>班级类型：</span>
          <span>
            <el-select v-model="groupType" placeholder="请选择班级类型" class="input-div search-el-select">
              <!-- 查没有绑定课程的项目 -->
              <el-option v-for="item in classTypeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </span>
        </span>
        <br v-if="leftDialogSign == 'insert'" />
        <br />
        <span v-if="leftDialogSign == 'insert'">
          <span>所属课程：</span>
          <span>
            <el-select v-model="course_id" placeholder="请选择班级所属课程" class="input-div search-el-select">
              <!-- 查没有绑定课程的项目 -->
              <el-option v-for="item in classList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </span>
        </span>
      </span>
      <span v-else-if="leftDialogSign == 'delete'">
        <h3>您确定要删除班级 “{{ groupName }}” 么？</h3>
      </span>
      <span v-else-if="leftDialogSign == 'delete_completely'">
        <h3>您确定要彻底删除班级 “{{ groupName }}” 么？</h3>
      </span>
      <span v-else-if="leftDialogSign == 'recover'">
        <h3>您确定要恢复班级 “{{ groupName }}” 么？</h3>
      </span>

      <!-- 底部按钮 -->
      <span slot="footer" class="dialog-footer">
        <el-button class="themecancelButton" @click="leftDialogVisibleClose">取 消</el-button>
        <el-button class="themeButton" @click="leftHandleExec()">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 右侧模块 -->
    <div class="div border0">
      <!-- 标签页 -->
      <el-tabs v-model="activeName" @tab-click="tabHandleClick">
        <!-- 虚 拟 -->
        <el-tab-pane label="学情分析" name="first">
          <div v-if="rightContentShow" class="setDetail">
            <div v-if="expList.length > 0" class="virtual">
              <div class="expSearch">
                <el-input clearable placeholder="请输入项目名称" v-model="virtualSearch" @clear="getVirtualDataList">
                  <el-button slot="append" class="el-icon-search" @click="getVirtualDataList"></el-button>
                </el-input>
              </div>
              <div>
                <el-date-picker v-model="virtualDate" type="daterange" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                  range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
                <el-select v-model="virtualExpId" placeholder="请选择" clearable class="expSelect">
                  <el-option v-for="item in expList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
                <!-- 不点统计无法导出 -->
                <el-button class="themecancelButton" @click="courseExpCensus()">统计</el-button>
                <div class="themeText floatRight" v-if="virtualDataShow" @click="exportVirtualData()">导出Excel</div>
              </div>
              <!-- 数据显示 + echarts -->
              <div class="data clearFix">
                <div class="data clearFix" :class="virtualDataShow ? '' : 'hiddenEcharts'">
                  <!-- 数据显示 -->
                  <div class="data-show">
                    <div class="width100 item-data">
                      <div class="left width50">关卡数</div>
                      <div class="float-right width50">{{ virtualData.gks }}</div>
                    </div>
                    <div class="width100 item-data">
                      <div class="left width50">班级人数</div>
                      <div class="float-right width50">{{ virtualData.qzrs }}</div>
                    </div>
                    <div class="width100 item-data">
                      <div class="left width50">完成人数</div>
                      <div class="float-right width50">{{ virtualData.wcr }}</div>
                    </div>
                    <div class="width100 item-data">
                      <div class="left width50">完成度</div>
                      <div class="float-right width50">{{ virtualData.wcd }}</div>
                    </div>
                    <div class="width100 item-data">
                      <div class="left width50">数据总数</div>
                      <div class="float-right width50">{{ virtualData.sj }}</div>
                    </div>
                    <div class="width100 item-data">
                      <div class="left width50">通关时间</div>
                      <div class="float-right width50">{{ virtualData.tgsj }}</div>
                    </div>
                    <div class="width100 item-data">
                      <div class="left width50">关卡时间</div>
                      <div class="float-right width50">{{ virtualData.gksj }}</div>
                    </div>
                    <div class="width100 item-data">
                      <div class="left width50">通关时间/关卡时间</div>
                      <div class="float-right width50">{{ virtualData.bz }}</div>
                    </div>
                    <!-- 关卡数此处循环 -->
                    <div class="width100 item-data" v-for="(item, index) in virtualData.list" :key="index">
                      <div class="left width50" @click="showExpStatDialog(index + 1)" style="cursor:pointer">
                      关卡 {{ index + 1 }}
                        <img src="../../../assets/img/箭头3x.png" style="width: 15px;">
                      </div>
                      <div class="float-right width50">
                        平均时间:{{ item.pjsj }}
                        <div class="left width30px"></div>
                        重复度:{{ item.cfd }}
                      </div>
                    </div>

                    <div class="width100 item-data">
                      <div class="left width50">总计</div>
                      <div class="float-right width50">{{ virtualData.zsj }}</div>
                    </div>
                  </div>
                  <!-- echarts -->
                  <div class="data-echarts">
                    <!-- 两个饼图 -->
                    <div>
                      <!-- 左侧饼图 -->
                      <div class="left pie" id="pie1"></div>
                      <!-- 右侧饼图 -->
                      <div class="left pie" id="pie2"></div>
                    </div>
                    <!-- 一个柱形图 -->
                    <div id="bar1" class="bar"></div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <h3>未绑定虚拟项目</h3>
              <br />
            </div>
          </div>
          <div v-else>
            <div class="recover-button">
              <h3>{{ groupName }}</h3>
              <br />
              <el-button class="themeButton" @click="leftHandleOpen('recover', '恢复项目')">恢复</el-button>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>

    <!-- 右侧学生列表——弹窗 -->
    <el-dialog :title="rightStudentDialogTitle" :visible.sync="rightStudentDialogVisible" width="30%">
      <!-- 解锁 -->
      <span v-if="rightStudentDialogSign == 'unlock'">
        <h3>全部解锁需要花费{{ lockPoint }}积分</h3>
      </span>
      <!-- 刷分 -->
      <span v-if="rightStudentDialogSign == 'brush'">
        <h3>请选择您要对学生自动刷分的操作，学生未注册，项目未绑定虚拟项目可能会造成自动刷分失效!</h3>
      </span>
      <!-- 写入 -->
      <span v-if="rightStudentDialogSign == 'insert'">
        <el-input class="themeInput" v-model="studentNumber" placeholder="请输入学号，多个学号以一个空格分隔！"></el-input>
      </span>
      <!-- 删除 -->
      <span v-else-if="rightStudentDialogSign == 'delete'">
        <h3>您确定要删除这些学生么？</h3>
      </span>
      <!-- 下载模板 -->
      <span v-else-if="rightStudentDialogSign == 'import'">
        <span class="uploadtipButton" @click="downloadImportTemplate()">点击下载模板</span>
        <!-- <div class="download-button" @click="downloadImportTemplate()">
                    <i class="el-icon-bottom" ></i>点击下载模板
                </div><br>
                <div class="download-title">
                    导入成绩后会覆盖之前的成绩
        </div><br>-->
        <div>
          <el-upload class="upload-demo" :action="uploadUrl" :data="{ id: group_id }" :on-success="markHandleAvatarSuccess"
            :on-remove="handleRemove" :before-remove="beforeRemove" :limit="1" :on-exceed="handleExceed"
            :before-upload="markHandleBeforeUpload" :file-list="fileList">
            <el-button class="uploadButton">点击上传文件</el-button>
            <div slot="tip" class="el-upload__tip">只能上传xls/xlsx文件，且不超过5MB</div>
          </el-upload>
        </div>
      </span>
      <!-- 发布成绩 -->
      <span v-else-if="rightStudentDialogSign == 'markRelease'">
        <h3>发布成绩时间较长，请耐心等待！</h3>
      </span>
      <!-- 导出证书 -->
      <span v-if="rightStudentDialogSign == 'exportCert'">
        <!-- 选择小游戏 -->
        <div class="selectGame">
          请选择小游戏: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <el-select disabled class="themeInput" v-model="game_id" placeholder="请选择小游戏" @change="getGameTypeList">
            <el-option v-for="item in gamelist" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </div>
        <br />
        <!-- 选择小游戏类型 -->
        <div class="selectGameType">
          请选择小游戏类型: &nbsp;&nbsp;
          <el-select class="themeInput" v-model="game_type_id" placeholder="请选择小游戏类型">
            <el-option v-for="item in gameTypelist" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </div>
      </span>
      <!-- 底部按钮 -->
      <span slot="footer" class="dialog-footer" v-if="rightStudentDialogSign != 'brush'">
        <el-button class="themecancelButton" @click="rightStudentDialogVisible = false">取 消</el-button>
        <el-button class="themeButton" @click="rightStudentHandleExec()">确 定</el-button>
      </span>
      <!-- 刷分 -->
      <span slot="footer" class="dialog-footer" v-if="rightStudentDialogSign == 'brush'">
        <el-button @click="removeAutoBrushPoint()">清除自动刷分</el-button>
        <el-button class="themeButton" @click="setAutoBrushPoint()">设置自动刷分</el-button>
      </span>
    </el-dialog>

    <!-- 导出成绩弹窗 -->
    <el-dialog title="导出成绩" :visible.sync="scoreDialogVisible" width="50%">
      <span>
        <el-checkbox v-model="rawScoreSign">原始成绩</el-checkbox>
        <el-checkbox v-model="calcScoreSign">权重成绩</el-checkbox>
        <el-checkbox v-model="openTimeSign">预约时间</el-checkbox>
        <br><br>
        <span style="color:rgb(137 132 132)">未发布成绩只能导出原始成绩和预约时间！</span>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="scoreDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="exportStudentListHandleOk">确 定</el-button>
      </span>
    </el-dialog>

    <div class="stasticDialogVisible">
      <el-dialog title="统计" :visible.sync="stasticDialogVisible" width="70%">
        <!-- 时间选择框 + 按钮 -->
        <div class="selectVir">
          <el-date-picker v-model="virtualDate" type="daterange" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
            range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
          <el-select v-model="virtualExpId" placeholder="请选择" clearable class="expSelect">
            <el-option v-for="item in expList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
          <!-- 不点统计无法导出 -->
          <el-button class="themeButton" @click="courseExpCensus()">统计</el-button>
          <div class="themeText floatRight" v-if="virtualDataShow" @click="exportVirtualData()">导出Excel</div>
        </div>
        <!-- 数据显示 + echarts -->
        <div class="data clearFix">
          <div class="data clearFix" :class="virtualDataShow ? '' : 'hiddenEcharts'">
            <!-- 数据显示 -->
            <div class="data-show">
              <div class="width100 item-data">
                <div class="left width50">关卡数</div>
                <div class="float-right width50">{{ virtualData.gks }}</div>
              </div>
              <div class="width100 item-data">
                <div class="left width50">班级人数</div>
                <div class="float-right width50">{{ virtualData.qzrs }}</div>
              </div>
              <div class="width100 item-data">
                <div class="left width50">完成人数</div>
                <div class="float-right width50">{{ virtualData.wcr }}</div>
              </div>
              <div class="width100 item-data">
                <div class="left width50">完成度</div>
                <div class="float-right width50">{{ virtualData.wcd }}</div>
              </div>
              <div class="width100 item-data">
                <div class="left width50">数据总数</div>
                <div class="float-right width50">{{ virtualData.sj }}</div>
              </div>
              <div class="width100 item-data">
                <div class="left width50">通关时间</div>
                <div class="float-right width50">{{ virtualData.tgsj }}</div>
              </div>
              <div class="width100 item-data">
                <div class="left width50">关卡时间</div>
                <div class="float-right width50">{{ virtualData.gksj }}</div>
              </div>
              <div class="width100 item-data">
                <div class="left width50">通关时间/关卡时间</div>
                <div class="float-right width50">{{ virtualData.bz }}</div>
              </div>
              <!-- 关卡数此处循环 -->
              <div class="width100 item-data" v-for="(item, index) in virtualData.list" :key="index">
                <div class="left width50">关卡 {{ index + 1 }}</div>
                <div class="float-right width50">
                  平均时间:{{ item.pjsj }}
                  <div class="left width30px"></div>
                  重复度:{{ item.cfd }}
                </div>
              </div> 
              <div class="width100 item-data">
                <div class="left width50">总计</div>
                <div class="float-right width50">{{ virtualData.zsj }}</div>
              </div>
            </div>
            <!-- echarts -->
            <div class="data-echarts">
              <!-- 两个饼图 -->
              <div>
                <!-- 左侧饼图 -->
                <div class="left pie" id="pie1"></div>
                <!-- 右侧饼图 -->
                <div class="left pie" id="pie2"></div>
              </div>
              <!-- 一个柱形图 -->
              <div id="bar1" class="bar"></div>
            </div>
          </div>
        </div>
      </el-dialog>
    </div>

    <div class="allunlockDialog" v-show="allunlockDialogVisible">
      <!-- 添加/修改/删除/恢复项目弹窗 -->
      <el-dialog title="提示" :visible.sync="allunlockDialogVisible" width="30%">
        <span>
          <div class="unlockText">账户余额：{{ balance }} 积分</div>
          <div class="unlockText">实验 {{ lockNum }} 人购买需：{{ totalMoneySpent }} 积分</div>
          <div class="unlockText">是否购买？</div>
        </span>
        <span slot="footer" class="dialog-footer">
          <el-button class="unlockcancelButton" @click="allunlockDialogVisible = false">取消</el-button>
          <el-button class="unnlockaddButton" @click="allUnlock">确定</el-button>
        </span>
      </el-dialog>
    </div>

    <div class="gamedataDialog" v-show="gamedataDialogVisible">
      <!-- 添加/修改/删除/恢复项目弹窗 -->
      <el-dialog :title="gamedataDialogTitle" :visible.sync="gamedataDialogVisible" width="60%">
        <el-table :data="gamedataList" stripe style="width: 100%">
          <el-table-column prop="subtime" label="提交时间"></el-table-column>
          <!-- 学号 -->
          <el-table-column prop="typeName" label="类型"></el-table-column>
          <!-- 姓名 -->
          <el-table-column prop="levelName" label="关卡名称"></el-table-column>
          <!-- 解锁 -->
          <el-table-column prop="score" label="分数"></el-table-column>
          <!-- 虚拟实验操作记录 -->
          <el-table-column prop="time" label="耗时(s)"></el-table-column>
        </el-table>
        <el-pagination @current-change="getGameDataList" background layout="prev, pager, next"
          :current-page.sync="gamedatalistPage" :total="gamedatalistTotal" :page-size="8"
          v-if="gamedatalistTotal != 0"></el-pagination>
        <!-- <span
            slot="footer"
            class="dialog-footer">
            <el-button class="unlockcancelButton" @click="gamedataDialogVisible = false">取消</el-button>
            <el-button class="unnlockaddButton" @click="allUnlock">确定</el-button>
        </span>-->
      </el-dialog>
    </div>

    <el-dialog @close="noticeDialogClose" title="发送通知" :visible.sync="sendNoticeDialogVisible">
      <div class="tips">
        请学生关注moolsnet公众号，点击授权，及时接收通知！
      </div>
      <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 8 }" placeholder="请输入内容" v-model="noticeValue">
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button class="themecancelButton" @click="sendNoticeDialogVisible = false">取消</el-button>
        <el-button class="themeButton" type="primary" @click="sendNotice">发送</el-button>
      </span>
    </el-dialog>

    <!-- 班级码对话框 -->
    <el-dialog class="groupCodeDialog" :title="student_group_name" :visible.sync="groupDialogVisible" @close="closeTimeIntervel"
      width="30%" center>
      <div class="groupCode">
        <div class="signInorOutCodeTitle">扫码加入班级(有效期24小时)</div>
        <img :src="groupCodeImgSrc" style="width: 15.5rem; height: 15.5rem" alt="">
        <h4>{{ timenow }}</h4>
        <span slot="footer" class="dialog-footer">
          <el-button class="clostSignCode" type="primary" @click="closegroupDialog">关闭</el-button>
        </span>
      </div>
    </el-dialog>
    
    <!-- 学情分析 echarts 弹窗 -->
    <el-dialog class="echartsDialog" :title="echartsDialogTitle" :visible.sync="echartsDialogVisible" :width="echartsDialogWidth" center>
      <div class="groupCode" id="mains" ></div>
      <div>
        <el-pagination
          background
          layout="prev, pager, next"
          :total="echartsDialogTotal"
          :page-size="5"
          :current-page="statPage"
          @current-change="changeEchartsPage">
        </el-pagination>
      </div>
    </el-dialog>

    <!-- add buy wyf 2023-07-28 -->
    <!-- 学生成绩总分分布统计图 -->
    <el-dialog class="studentScoreDialog"  :visible.sync="studentScoreDialogVisible" width="50%" center>
      <!-- 当前班级名称 -->
    <div >
    {{ groupName }}
    </div>
    <!-- 表格 -->
    <el-row class="main" >
      <el-col style="font-size: 0.938vw; text-align: center;">总分分布</el-col>
      <el-col :span="12">
        <!-- <div class="grid-content bg-purple"></div> -->

        <el-row class="score" >
          <el-col class="td" :span="4">分数段</el-col>
          <el-col class="td" :span="4">[90,100]</el-col>
          <el-col class="td" :span="4">[80,90)</el-col>
          <el-col class="td" :span="4">[70,80)</el-col>
          <el-col class="td" :span="4">[60,70)</el-col>
          <el-col class="td" :span="4">[0,60)</el-col>
        </el-row>

        <el-row class="score" >
          <el-col class="td" :span="4">人数</el-col>
          <el-col class="td" :span="4">{{ studentScore.ten_nine_number ? studentScore.ten_nine_number :0 }}</el-col>
          <el-col class="td" :span="4">{{ studentScore.nine_eight_number ? studentScore.nine_eight_number :0 }}</el-col>
          <el-col class="td" :span="4">{{ studentScore.eight_seven_number ? studentScore.eight_seven_number :0 }}</el-col>
          <el-col class="td" :span="4">{{ studentScore.seven_six_number ? studentScore.seven_six_number :0 }}</el-col>
          <el-col class="td" :span="4">{{ studentScore.zero_six_number ? studentScore.zero_six_number :0 }}</el-col>
        </el-row>

        <el-row class="score" >
          <el-col class="td" :span="4">百分比</el-col>
          <el-col class="td" :span="4">{{ studentScore.ten_nine_percent ? studentScore.ten_nine_percent :0 }}%</el-col>
          <el-col class="td" :span="4">{{ studentScore.nine_eight_percent ? studentScore.nine_eight_percent :0 }}%</el-col>
          <el-col class="td" :span="4">{{ studentScore.eight_seven_percent ? studentScore.eight_seven_percent :0 }}%</el-col>
          <el-col class="td" :span="4">{{ studentScore.seven_six_percent ? studentScore.seven_six_percent :0 }}%</el-col>
          <el-col class="td" :span="4">{{ studentScore.zero_six_percent ? studentScore.zero_six_percent :0 }}%</el-col>
        </el-row>
      </el-col>
      <el-col :span="12">
        <!-- <div class="grid-content bg-purple-light"></div> -->
        <el-row class="score" style="border-right: 1px solid #ccc;" >
          <el-col class="td" :span="4">最高分</el-col>
          <el-col class="td" :span="4">最低分</el-col>
          <el-col class="td" :span="4">平均分</el-col>
          <el-col class="td" :span="4">及格率</el-col>
          <el-col class="td" style="border-right: 0;" :span="4">相对偏差</el-col>
        </el-row>
        <el-row class="score" style="border-right: 1px solid #ccc;">
          <el-col class="td" :span="4">{{ studentScore.maxScore ? studentScore.maxScore :0 }}</el-col>
          <el-col class="td" :span="4">{{ studentScore.minScore ? studentScore.minScore :0 }}</el-col>
          <el-col class="td" :span="4">{{ studentScore.avgScore ? studentScore.avgScore :0 }}</el-col>
          <el-col class="td" :span="4">{{ (studentNum - studentScore.zero_six_number) / studentNum ? (studentNum - studentScore.zero_six_number) / studentNum : 0 }}%</el-col>
          <el-col class="td" style="border-right: 0;" :span="4">{{ studentScore.relativeDev ? studentScore.relativeDev :0 }}%</el-col>
        </el-row>
        <el-row class="score" style="border-right: 1px solid #ccc;">
          <el-col class="td" :span="4"></el-col>
          <el-col class="td" :span="4"></el-col>
          <el-col class="td" :span="4"></el-col>
          <el-col class="td" :span="4"></el-col>
          <el-col class="td" style="border-right: 0;" :span="4"></el-col>
        </el-row>
      </el-col>
    </el-row>
    <!-- 图表 -->
    <div id="studentScore"></div>

    </el-dialog>

  </div>
</template>
<style lang="scss" >
@import "@/assets/css/teacher/group/groupListnoScoped.scss";
.groupCode{
  width:100%;height:500px;
}
.echartsDialog{
  
}

.el-radio__input.is-checked+.el-radio__label {
  color: #0D5570 !important;
}

.el-radio__input.is-checked .el-radio__inner {
  border-color: #0D5570 !important;
  background: #0D5570 !important;
}

.el-dialog__body {
  padding: 30px 20px;
  padding-top: 5px !important;
  color: #606266;
  font-size: 14px;
  word-break: break-all;
  padding-bottom: 30px;
  text-align: center;
}

.pdf .is-active {
  border-left: 0 !important;
}

.weight .el-table .el-table__cell {
  padding: 0 !important;
}
</style>
<style lang="scss" scoped>
@import "@/assets/css/teacher/other/xueqing.scss";
</style>
<script>
import groupList from "@/assets/js/teacher/other/xueqing.js";
export default {
  ...groupList
};
</script>
